export const platformUserLoginUrl = () => {
    return `/login`;
};

export const platformUserLogoutUrl = () => {
    return `/logout`;
};

export const platformUserRegisterUrl = () => {
    return `/register`;
};

export const platformUserProfileUrl = () => {
    return `/profile`;
};

export const getPlatformUserUrl = () => {
    return `/users`;
};

export const deletePlatformUserUrl = () => {
    return `/users`;
};

export const changePasswordUrl = () => {
    return `/users`;
};
