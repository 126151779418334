export const getAllBeneficiaryUrl = () => {
    return "/users/beneficiaries";
};

export const getAddBeneficiaryUrl = () => {
    return "/bankaccount/addUserAndBeneficiary";
};

export const getEditBeneficiaryUrl = id => {
    return `/beneficiaries/${id}`;
};

export const getAccountBalanceURL = () => {
    return `/bankaccount/balance`;
};

export const getDeleteBeneficiaryUrl = id => {
    return `/bankaccount/delete-beneficiary`;
};
export const getVerifyBeneficiaryUrl = id => {
    return `/bankaccount/verify-beneficiary`;
};
export const getFundTransferUrl = () => {
    return "/bankaccount/fundtransfer";
};

export const getApproveFundTransferUrl = () => {
    return "/bankaccount/approvefundtransfer";
};

export const getRetryFundTransferUrl = () => {
    return "/bankaccount/retryfundtransfer";
};

export const getRejectFundTransferUrl = () => {
    return "/bankaccount/rejectfundtransfer";
};
export const getStatusFundTransferUrl = () => {
    return "/bankaccount/statusfundtransfer";
};
export const getSearchFarmerURL = () => {
    return `/filter/customerByPhoneNumber`;
};

export const getSearchMerchantUrl = () => {
    return "/filter/retailerByPhoneNumber";
};

export const getSearchProductSupplierUrl = () => {
    return "/filter/productSupplierByPhoneNumber";
};

export const getSearchOtherSupplierUrl = () => {
    return "/filter/otherSupplierByPhoneNumber";
};

export const getSearchVendorsUrl = () => {
    return "/filter/vendorsByPhoneNumber";
};

export const searchFilterBenName = () => {
    return "/filter/beneficiaryByName";
};

export const searchFilterBenNumber = () => {
    return "/filter/beneficiaryByNumber";
};
