function setData(state, data) {
    const { transaction_groups } = data;
    if (transaction_groups) {
        return { ...state, ...transaction_groups };
    }
    return state;
}

export default (state = {}, action = {}) => {
    const { type, payload = {} } = action;
    switch (type) {
        default:
            return setData(state, payload);
    }
};
