export const getChequesHistoryUrl = () => {
    return "/cheques";
};

export const getChequesHistorybyTransactionIdUrl = transaction_id => {
    return `/cheques/${transaction_id}`;
};

export const getChequesRequestsUrl = () => {
    return "/cheques/requests";
};

export const getChequesRequestbyTransactionIdUrl = transaction_id => {
    return `/cheques/requests/${transaction_id}`;
};

export const updateChequeDetailsUrl = transaction_id => {
    return `/cheques/requests/${transaction_id}`;
};
