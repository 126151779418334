import { GET_ERRORS, CLEAR_ERRORS } from "../../constant";

export const returnErrors = (message, status, id = null) => {
    return {
        type: GET_ERRORS,
        payload: {
            message,
            status,
            id
        }
    };
};

export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    };
};
const initialState = {
    message: {},
    status: null,
    id: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            return {
                message: action.payload.message,
                status: action.payload.status,
                id: action.payload.id
            };

        case CLEAR_ERRORS:
            return {
                message: {},
                status: null,
                id: null
            };

        default:
            return state;
    }
}
