import { xFetch } from "../../helpers/xhrHelpers";
import {
    getAddBeneficiaryUrl,
    getAllBeneficiaryUrl
} from "../../helpers/url/beneficiary";
import { REQUEST_TYPE } from "../../constant";

const ADD_BENEFICIARY_SUCCESS = "ADD_BENEFICIARY_SUCCESS";
const FETCHING_BENEFICIARIES_SUCCESS = "FETCHING_BENEFICIARIES_SUCCESS";

export const getBeneficiaries = () => {
    return async dispatch => {
        let response = {};
        try {
            response = await xFetch({
                url: getAllBeneficiaryUrl(),
                method: REQUEST_TYPE.GET
            });
            const { status } = response;
            if (status) {
                const {
                    payload: { data }
                } = response;
                dispatch({
                    type: FETCHING_BENEFICIARIES_SUCCESS,
                    payload: data
                });
            }
        } catch (e) {
            console.log("error in fetching beneficiary", e);
        }
        return response;
    };
};

export const addBeneficiary = formData => {
    return async dispatch => {
        let response = {};
        try {
            response = await xFetch({
                url: getAddBeneficiaryUrl(),
                method: REQUEST_TYPE.POST,
                body: formData
            });
            const { status } = response;
            if (status) {
                const {
                    payload: { data }
                } = response;
                dispatch({ type: ADD_BENEFICIARY_SUCCESS, payload: data });
            }
        } catch (e) {
            console.log("error in add beneficiary", e);
        }
        return response;
    };
};

function setBeneficiaries(state, data) {
    const { beneficiaries } = data;
    if (beneficiaries) {
        return { ...state, ...beneficiaries };
    }
    return state;
}

export default (state = {}, action = {}) => {
    const { type, payload = {} } = action;
    switch (type) {
        default:
            return setBeneficiaries(state, payload);
    }
};
