import { connect } from "react-redux";
import Sidebar from "../../components/sidebar";
import { withRouter } from "react-router";
import { logout } from "../../modules/auth";
const mapStateToProps = state => {
    const { auth, error } = state;

    return {
        auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            return dispatch(logout());
        }
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
