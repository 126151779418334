const OPEN_DRAWER = "OPEN_DRAWER";
const CLOSE_DRAWER = "CLOSE_DRAWER";

const initial_state = {
    visible: false
};

export const open = data => {
    return {
        type: OPEN_DRAWER,
        data
    };
};

export const close = () => {
    return {
        type: CLOSE_DRAWER
    };
};

export default (state = initial_state, action) => {
    const { type, data } = action;
    switch (type) {
        case OPEN_DRAWER:
            return {
                visible: true,
                data
            };
        case CLOSE_DRAWER:
            return {
                visible: false
            };
        default:
            return state;
    }
};
