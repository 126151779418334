import App from "../App";
import { connect } from "react-redux";
import { loadUser } from "../modules/auth";

const mapStateToProps = state => {
    const { auth: { isAuthenticated } = {} } = state;

    return { isAuthenticated };
};

const mapDispatchToProps = dispatch => {
    return { loadUser: data => dispatch(loadUser()) };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
