import { xFetch } from "../../helpers/xhrHelpers";
import { REQUEST_TYPE } from "../../constant";
import {
    getChequesRequestsUrl,
    getChequesRequestbyTransactionIdUrl
} from "../../helpers/url/cheques";
const FETCH_CHEQUES_REQUEST_TRANSACTIONS_COMPLETED =
    "FETCH_CHEQUES_REQUEST_TRANSACTIONS_COMPLETED";
const FETCH_CHEQUES_REQUEST_TRANSACTIONS_ERROR =
    "FETCH_CHEQUES_REQUEST_TRANSACTIONS_ERROR";
const FETCH_CHEQUES_REQUEST__BY_TRANSACTIONS_ID_COMPLETED =
    "FETCH_CHEQUES_REQUEST__BY_TRANSACTIONS_ID_COMPLETED";
const FETCH_CHEQUES_REQUEST__BY_TRANSACTIONS_ID_ERROR =
    "FETCH_CHEQUES_REQUEST__BY_TRANSACTIONS_ID_ERROR";

export const fetchChequesRequestByTransactionId = transaction_id => {
    return async dispatch => {
        let response = {};
        try {
            const url = getChequesRequestbyTransactionIdUrl(transaction_id);
            response = await xFetch({
                url: url,
                method: REQUEST_TYPE.GET
            });
            const { status } = response;
            if (status) {
                const { payload: { data = {} } = {} } = response;
                dispatch({
                    type: FETCH_CHEQUES_REQUEST__BY_TRANSACTIONS_ID_COMPLETED,
                    payload: data
                });
            }
        } catch (e) {
            dispatch({
                type: FETCH_CHEQUES_REQUEST__BY_TRANSACTIONS_ID_ERROR
            });
        }

        return response;
    };
};

export const fetchChequesRequestTransactions = data => {
    return async dispatch => {
        let response = {};
        try {
            const url = getChequesRequestsUrl();
            response = await xFetch({
                headers: {
                    "Content-Type": "application/json"
                },
                url: url,
                method: REQUEST_TYPE.GET,
                body: data
            });
            const { status } = response;
            if (status) {
                const { payload: { data = {} } = {} } = response;
                dispatch({
                    type: FETCH_CHEQUES_REQUEST_TRANSACTIONS_COMPLETED,
                    payload: data
                });
            }
        } catch (e) {
            dispatch({
                type: FETCH_CHEQUES_REQUEST_TRANSACTIONS_ERROR
            });
        }

        return response;
    };
};

function setChequesRequestByTransactionId(state, data) {
    const { cheques_requests_by_transaction_id } = data;
    if (cheques_requests_by_transaction_id) {
        return { ...state, ...cheques_requests_by_transaction_id };
    }
    return state;
}

function setChequesRequestTransactions(state, data) {
    const { cheques_requests, cheques_requests_ids, pages } = data;
    const cheques_requests_transactions = {
        cheques_requests,
        cheques_requests_ids,
        pages
    };
    if (cheques_requests_transactions) {
        return { ...state, ...cheques_requests_transactions };
    }
    return state;
}

export default (state = {}, action = {}) => {
    const { type, payload = {} } = action;
    switch (type) {
        case FETCH_CHEQUES_REQUEST_TRANSACTIONS_COMPLETED:
            return setChequesRequestTransactions(state, payload);
        case FETCH_CHEQUES_REQUEST__BY_TRANSACTIONS_ID_COMPLETED:
            return setChequesRequestByTransactionId(state, payload);
        default:
            return state;
    }
};
