import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Menu, Popover } from "antd";
import { BankOutlined } from "@ant-design/icons";
import { PATH } from "../../constant";
import productIcon from "../../assets/images/sidebar/pay.png";
import dashboardIcon from "../../assets/images/sidebar/dashboard.svg";
import adminIcon from "../../assets/images/sidebar/server.svg";
import settingIcon from "../../assets/images/sidebar/settings.svg";
import userIcon from "../../assets/images/sidebar/user.svg";
import addBeneficiariesIcon from "../../assets/images/sidebar/add.svg";
import fundTransferHistoryIcon from "../../assets/images/sidebar/history.svg";
import retailerOrders from "../../assets/images/sidebar/order.svg";
import chequeIcon from "../../assets/images/sidebar/cheque.svg";
import machineIcon from "../../assets/images/sidebar/machine.svg";
const { SubMenu } = Menu;

const LOGO = "logo";
const DASHBOARD = "dashboard";
const BENEFICIARIES = "beneficiaries";
const FUND_TRANSFER_HISTORY = "fund-transfers-history";
const FUND_TRANSFER_REQUESTS = "fund-transfers-requests";
const ADMIN = "admin";
const LOGIN = "login";
const USER = "user";
const RETAILER_ORDERS = "retailer-orders";
const CHEQUES_HISTORY = "cheques-history";
const CHEQUES_REQUESTS = "cheques-requests";
const RECONCILIATIONS = "recon";
const DASHBOARD_SETTINGS = "settings";
const CHANGE_PASSWORD = "change-password";
const LOGOUT = "logout";
const MACHINE = "machine";

class Sidebar extends Component {
    handleItemSelect = ({ selectedKeys }) => {
        const { history } = this.props;
        switch (selectedKeys[0]) {
            case LOGO:
                history.push(PATH.DASHBOARD);
                break;
            case DASHBOARD:
                history.push(PATH.DASHBOARD);
                break;
            case RECONCILIATIONS:
                history.push(PATH.RECONCILIATION);
                break;
            case ADMIN:
                history.push(PATH.ADMIN);
                break;
            case MACHINE:
                history.push(PATH.MACHINE);
                break;
            case BENEFICIARIES:
                history.push(PATH.BENEFICIARIES);
                break;
            case FUND_TRANSFER_HISTORY:
                history.push(PATH.FUND_TRANSFER_HISTORY);
                break;
            case FUND_TRANSFER_REQUESTS:
                history.push(PATH.FUND_TRANSFER_REQUESTS);
                break;
            case LOGIN:
                history.push(PATH.LOGIN);
                break;
            case RETAILER_ORDERS:
                history.push(PATH.RETAILER_ORDERS);
                break;
            case CHEQUES_HISTORY:
                history.push(PATH.CHEQUES_HISTORY);
                break;
            case CHEQUES_REQUESTS:
                history.push(PATH.CHEQUES_REQUESTS);
                break;
            case DASHBOARD_SETTINGS:
                history.push(PATH.DASHBOARD_SETTINGS);
                break;
            case CHANGE_PASSWORD:
                history.push(PATH.CHANGE_PASSWORD);
                break;
            case LOGOUT:
                this.props.logout();
                break;
            case USER:
                break;
            default:
                history.push(PATH.ROOT);
                break;
        }
    };

    render() {
        const { selectedKeys, auth } = this.props;
        if (auth.isAuthenticated) {
            const title = "Welcome " + auth.user.name;
            if (auth.user.is_admin) {
                return (
                    <Menu
                        theme="dark"
                        className="side-bar-menu"
                        selectedKeys={selectedKeys}
                        onSelect={this.handleItemSelect}
                        style={{
                            width: "80px",
                            height: "100%",
                            overflow: "auto"
                        }}
                    >
                        <Menu.Item
                            className="flex direction-column justify-center align-center p0"
                            key={LOGO}
                            title="Payment Services"
                        >
                            <img
                                alt=""
                                style={{ width: "32px" }}
                                src={productIcon}
                            />
                        </Menu.Item>
                        <Menu.Item
                            className="flex direction-column justify-center align-center"
                            key={DASHBOARD}
                            title="Retailer Reconciliation"
                            style={{ height: "70px" }}
                        >
                            <img
                                alt=""
                                style={{ width: "26px" }}
                                src={dashboardIcon}
                            />
                            <div
                                style={{
                                    lineHeight: "13px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "center",
                                    paddingTop: "6px",
                                    fontSize: "10px"
                                }}
                            >
                                Farmer Orders
                            </div>
                        </Menu.Item>
                        <Menu.Item
                            className="flex direction-column justify-center align-center"
                            key={RETAILER_ORDERS}
                            title="Retailer Orders"
                            style={{ height: "70px" }}
                        >
                            <img
                                alt=""
                                style={{ width: "26px" }}
                                src={retailerOrders}
                            />
                            <div
                                style={{
                                    lineHeight: "13px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "center",
                                    paddingTop: "6px",
                                    fontSize: "10px"
                                }}
                            >
                                Retailer Orders
                            </div>
                        </Menu.Item>
                        <Menu.Item
                            className="flex direction-column justify-center align-center"
                            key={RECONCILIATIONS}
                            title="Retailer Reconciliation"
                            style={{ height: "70px" }}
                        >
                            <img
                                alt=""
                                style={{ width: "26px" }}
                                src={retailerOrders}
                            />
                            <div
                                style={{
                                    lineHeight: "13px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "center",
                                    paddingTop: "6px",
                                    fontSize: "10px"
                                }}
                            >
                                Recon
                            </div>
                        </Menu.Item>
                        <Menu.Item
                            className="flex direction-column justify-center align-center p0"
                            key={BENEFICIARIES}
                            title="Add Beneficiaries"
                            style={{ height: "70px" }}
                        >
                            <img
                                alt=""
                                style={{ width: "26px" }}
                                src={addBeneficiariesIcon}
                            />
                            <div
                                style={{
                                    lineHeight: "13px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "center",
                                    paddingTop: "6px",
                                    fontSize: "10px"
                                }}
                            >
                                Add Beneficiaries
                            </div>
                        </Menu.Item>
                        <SubMenu
                            key="fund-transfer"
                            style={{ height: "80px", textAlign: "center" }}
                            id="fund-transfer-icon"
                            title={
                                <>
                                    <img
                                        style={{
                                            width: "26px"
                                        }}
                                        src={fundTransferHistoryIcon}
                                    />
                                    <div
                                        style={{
                                            lineHeight: "13px",
                                            whiteSpace: "pre-wrap",
                                            textAlign: "center",
                                            fontSize: "10px"
                                        }}
                                    >
                                        Fund Transfers
                                    </div>
                                </>
                            }
                        >
                            <Menu.Item key={FUND_TRANSFER_HISTORY}>
                                Fund Transfer History
                            </Menu.Item>
                            <Menu.Item key={FUND_TRANSFER_REQUESTS}>
                                Fund Transfer Requests
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item
                            className="flex direction-column justify-center align-center p0"
                            key={MACHINE}
                            title="Machine"
                            style={{ height: "70px" }}
                        >
                            <img
                                alt=""
                                style={{ width: "32px" }}
                                src={machineIcon}
                            />
                            <div
                                style={{
                                    lineHeight: "13px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "center",
                                    paddingTop: "6px",
                                    fontSize: "10px"
                                }}
                            >
                                Machine
                            </div>
                        </Menu.Item>
                        <SubMenu
                            key="cheques"
                            style={{ height: "80px", textAlign: "center" }}
                            id="cheque-icon"
                            title={
                                <>
                                    <img
                                        style={{
                                            width: "26px"
                                        }}
                                        src={chequeIcon}
                                    />
                                    <div
                                        style={{
                                            lineHeight: "13px",
                                            whiteSpace: "pre-wrap",
                                            textAlign: "center",
                                            fontSize: "10px"
                                        }}
                                    >
                                        Cheques
                                    </div>
                                </>
                            }
                        >
                            <Menu.Item key={CHEQUES_REQUESTS}>
                                Cheques Requests
                            </Menu.Item>
                            <Menu.Item key={CHEQUES_HISTORY}>
                                Cheques History
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item
                            className="flex direction-column justify-center align-center p0"
                            key={ADMIN}
                            title="Admin"
                            style={{ height: "70px" }}
                        >
                            <img
                                alt=""
                                style={{ width: "26px" }}
                                src={adminIcon}
                            />
                            <div
                                style={{
                                    lineHeight: "13px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "center",
                                    paddingTop: "6px",
                                    fontSize: "10px"
                                }}
                            >
                                Admin
                            </div>
                        </Menu.Item>
                        <Menu.Item
                            className="flex direction-column justify-center align-center p0"
                            key={DASHBOARD_SETTINGS}
                            title="Dashboard-settings"
                        >
                            <img
                                alt=""
                                style={{ width: "26px" }}
                                src={settingIcon}
                            />
                            <div
                                style={{
                                    lineHeight: "13px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "center",
                                    paddingTop: "6px",
                                    fontSize: "10px"
                                }}
                            >
                                Settings
                            </div>
                        </Menu.Item>

                        {/* <Menu.Item
                            className="flex direction-column justify-center align-center p0"
                            style={{
                                position: "fixed",
                                bottom: "0",
                                marginLeft: "9px",
                                paddingTop: "6px"
                            }}
                            key={USER}
                        > */}

                        <SubMenu
                            placement="rightBottom"
                            key="user"
                            style={{ height: "80px", textAlign: "center" }}
                            id="user-icon"
                            title={
                                <>
                                    <img
                                        alt=""
                                        style={{ width: "26px" }}
                                        src={userIcon}
                                    />
                                </>
                            }
                        >
                            <Menu.Item key={CHANGE_PASSWORD}>
                                Change Password
                            </Menu.Item>
                            <Menu.Item key={LOGOUT}>Logout</Menu.Item>
                        </SubMenu>
                    </Menu>
                );
            } else {
                return (
                    <Menu
                        // selectedKeys={[selectedKeys]}
                        theme="dark"
                        className="side-bar-menu"
                        selectedKeys={selectedKeys}
                        onSelect={this.handleItemSelect}
                        defaultSelectedKeys={[DASHBOARD]}
                        style={{
                            width: "80px",
                            height: "100%",
                            overflow: "auto"
                        }}
                    >
                        <Menu.Item
                            className="flex direction-column justify-center align-center p0"
                            key={LOGO}
                            title="Payment Services"
                        >
                            <img
                                alt=""
                                style={{ width: "32px" }}
                                src={productIcon}
                            />
                        </Menu.Item>
                        <Menu.Item
                            className="flex direction-column justify-center align-center"
                            key={DASHBOARD}
                            title="Retailer Reconciliation"
                            style={{ height: "70px" }}
                        >
                            <img
                                alt=""
                                style={{ width: "26px" }}
                                src={dashboardIcon}
                            />
                            <div
                                style={{
                                    lineHeight: "13px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "center",
                                    paddingTop: "6px",
                                    fontSize: "10px"
                                }}
                            >
                                Farmer Orders
                            </div>
                        </Menu.Item>
                        <Menu.Item
                            className="flex direction-column justify-center align-center"
                            key={RETAILER_ORDERS}
                            title="Retailer Orders"
                            style={{ height: "70px" }}
                        >
                            <img
                                alt=""
                                style={{ width: "26px" }}
                                src={retailerOrders}
                            />
                            <div
                                style={{
                                    lineHeight: "13px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "center",
                                    paddingTop: "6px",
                                    fontSize: "10px"
                                }}
                            >
                                Retailer Orders
                            </div>
                        </Menu.Item>
                        <Menu.Item
                            className="flex direction-column justify-center align-center p0"
                            key={BENEFICIARIES}
                            title="Add Beneficiaries"
                            style={{ height: "70px" }}
                        >
                            <img
                                alt=""
                                style={{ width: "26px" }}
                                src={addBeneficiariesIcon}
                            />
                            <div
                                style={{
                                    lineHeight: "13px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "center",
                                    paddingTop: "6px",
                                    fontSize: "10px"
                                }}
                            >
                                Add Beneficiaries
                            </div>
                        </Menu.Item>
                        <SubMenu
                            key="fund-transfer"
                            style={{ height: "80px", textAlign: "center" }}
                            id="fund-transfer-icon"
                            title={
                                <>
                                    <img
                                        style={{
                                            width: "26px"
                                        }}
                                        src={fundTransferHistoryIcon}
                                    />
                                    <div
                                        style={{
                                            lineHeight: "13px",
                                            whiteSpace: "pre-wrap",
                                            textAlign: "center",
                                            fontSize: "10px"
                                        }}
                                    >
                                        Fund Transfers
                                    </div>
                                </>
                            }
                        >
                            <Menu.Item key={FUND_TRANSFER_HISTORY}>
                                Fund Transfer History
                            </Menu.Item>
                            <Menu.Item key={FUND_TRANSFER_REQUESTS}>
                                Fund Transfer Requests
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item
                            className="flex direction-column justify-center align-center p0"
                            key={CHEQUES_HISTORY}
                            title="Cheques History"
                            style={{ height: "70px" }}
                        >
                            <img
                                alt=""
                                style={{ width: "26px" }}
                                src={chequeIcon}
                            />
                            <div
                                style={{
                                    lineHeight: "13px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "center",
                                    paddingTop: "6px",
                                    fontSize: "10px"
                                }}
                            >
                                Cheques History
                            </div>
                        </Menu.Item>
                        <SubMenu
                            placement="rightBottom"
                            key="user"
                            style={{ height: "80px", textAlign: "center" }}
                            id="user-icon"
                            title={
                                <>
                                    <img
                                        alt=""
                                        style={{ width: "26px" }}
                                        src={userIcon}
                                    />
                                </>
                            }
                        >
                            <Menu.Item key={CHANGE_PASSWORD}>
                                Change Password
                            </Menu.Item>
                            <Menu.Item key={LOGOUT}>Logout</Menu.Item>
                        </SubMenu>
                    </Menu>
                );
            }
        } else {
            return (
                <Menu
                    // selectedKeys={[selectedKeys]}
                    theme="dark"
                    className="side-bar-menu"
                    selectedKeys={selectedKeys}
                    onSelect={this.handleItemSelect}
                    defaultSelectedKeys={[DASHBOARD]}
                    style={{
                        width: "80px",
                        height: "100%",
                        overflow: "auto"
                    }}
                >
                    <Menu.Item
                        className="flex direction-column justify-center align-center p0"
                        key={LOGIN}
                        title="Payment Services"
                    >
                        <img
                            alt=""
                            style={{ width: "26px" }}
                            src={productIcon}
                        />
                    </Menu.Item>
                </Menu>
            );
        }
    }
}

export default withRouter(Sidebar);
