function setCustomers(state, data) {
    const { customers } = data;
    if (customers) {
        return { ...state, ...customers };
    }
    return state;
}

export default (state = {}, action = {}) => {
    const { type, payload = {} } = action;
    switch (type) {
        default:
            return setCustomers(state, payload);
    }
};
