import { combineReducers } from "redux";
import transactions from "./transaction";
import orders from "./orders";
import customers from "./customers";
import retailers from "./retailers";
import stores from "./stores";
import transaction_groups from "./transaction_groups";
import transaction_history from "./transaction_history";
import transaction_group_history from "./transaction_group_history";
import fund_transfer_request_transaction_order_id from "./fund_transfer_transactions";
import drawer from "./drawer";
import auth from "./auth";
import error from "./error";
import beneficiaries from "./beneficiaries";
import fund_transfer_request_transactions from "./fund-transfer-request";
import cheque_history_transaction_id from "./cheques_history";
import cheques_requests_by_transaction_id from "./cheques_requests";
import cheques_requests_transactions from "./cheques_requests";

const rootReducer = combineReducers({
    transactions,
    orders,
    retailers,
    customers,
    stores,
    transaction_groups,
    transaction_history,
    transaction_group_history,
    fund_transfer_request_transaction_order_id,
    fund_transfer_request_transactions,
    cheque_history_transaction_id,
    cheques_requests_by_transaction_id,
    cheques_requests_transactions,
    drawer,
    auth,
    beneficiaries,
    error
});

export default (state, action) => {
    return rootReducer(state, action);
};
