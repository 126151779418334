function setStores(state, data) {
    const { stores } = data;
    if (stores) {
        return { ...state, ...stores };
    }
    return state;
}

export default (state = {}, action = {}) => {
    const { type, payload = {} } = action;
    switch (type) {
        default:
            return setStores(state, payload);
    }
};
