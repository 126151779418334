import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    DELETE_FAIL,
    DELETE_SUCCESS
} from "../../constant";
import { xFetch } from "../../helpers/xhrHelpers";
import { REQUEST_TYPE } from "../../constant";
import { platformUserProfileUrl } from "../../helpers/url/platformUsers";
import { platformUserRegisterUrl } from "../../helpers/url/platformUsers";
import { platformUserLoginUrl } from "../../helpers/url/platformUsers";
import { platformUserLogoutUrl } from "../../helpers/url/platformUsers";
import { deletePlatformUserUrl } from "../../helpers/url/platformUsers";
import { returnErrors } from "../error";
import { getCookie } from "../../helpers/cookie";

const initialState = {
    token: getCookie("access_token"),
    // token: localStorage.getItem("token"),
    isAuthenticated: null,
    isLoading: false,
    user: null,
    message: null
};

export const loadUser = () => (dispatch, getState) => {
    dispatch({ type: USER_LOADING });
    const token = getCookie("access_token");
    // const token = getState().auth.token;
    const url = platformUserProfileUrl();

    let response = {};
    xFetch({
        url: url,
        method: REQUEST_TYPE.POST
        // headers: {
        //     Authorization: "Bearer " + token
        // }
    }).then(response => {
        const { status, payload: { data, message } = {} } = response || {};
        if (status === true) {
            dispatch({
                type: USER_LOADED,
                payload: data
            });
        } else if (status === false) {
            dispatch(
                returnErrors(response.payload.message, response.payload.status)
            );
            dispatch({
                type: AUTH_ERROR
            });
        }
    });
};

export const registerUser = ({
    name,
    email,
    phone_number,
    is_admin
}) => dispatch => {
    const url = platformUserRegisterUrl();
    xFetch({
        url: url,
        method: REQUEST_TYPE.POST,
        body: {
            name: name,
            email: email,
            phone_number: phone_number,
            is_admin: is_admin
        }
    }).then(response => {
        const { status, payload: { data, message } = {} } = response || {};
        if (status === true) {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: message
            });
        } else if (status === false) {
            dispatch(
                returnErrors(
                    response.payload.message,
                    response.payload.status,
                    "REGISTER_FAIL"
                )
            );
            dispatch({
                type: REGISTER_FAIL,
                payload: message
            });
        }
    });
};

export const loginUser = ({ email, password }) => dispatch => {
    const url = platformUserLoginUrl();
    xFetch({
        url: url,
        method: REQUEST_TYPE.POST,
        body: {
            email: email,
            password: password
        }
    }).then(response => {
        const { status, payload: { data, message } = {} } = response || {};
        if (status === true) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: data
            });
        } else if (status === false) {
            dispatch(
                returnErrors(
                    response.payload.message,
                    response.payload.status,
                    "LOGIN_FAIL"
                )
            );
            dispatch({
                type: LOGIN_FAIL
            });
        }
    });
};

export const deleteUser = ({ email }) => dispatch => {
    const url = deletePlatformUserUrl();
    xFetch({
        url: url,
        method: REQUEST_TYPE.DELETE,
        body: {
            email: email
        }
    }).then(response => {
        const { status, payload: { data, message } = {} } = response || {};
        if (status === true) {
            dispatch({
                type: DELETE_SUCCESS,
                payload: message
            });
        } else if (status === false) {
            dispatch(
                returnErrors(
                    response.payload.message,
                    response.payload.status,
                    "DELETE_FAIL"
                )
            );
            dispatch({
                type: DELETE_FAIL,
                payload: message
            });
        }
    });
};

export const logout = () => dispatch => {
    const url = platformUserLogoutUrl();
    xFetch({
        url: url,
        method: REQUEST_TYPE.POST
    }).then(response => {
        const { status, payload: { data, message } = {} } = response || {};
        if (status === true) {
            dispatch({
                type: LOGOUT_SUCCESS,
                payload: data
            });
        }
    });
};

export default function(state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload.user
            };
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false,
                message: null
            };
        case REGISTER_FAIL:
        case DELETE_FAIL:
        case REGISTER_SUCCESS:
        case DELETE_SUCCESS:
            return {
                ...state,
                message: action.payload
            };
        default:
            return state;
    }
}
