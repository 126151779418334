function setRetailers(state, data) {
    const { retailers } = data;
    if (retailers) {
        return { ...state, ...retailers };
    }
    return state;
}

export default (state = {}, action = {}) => {
    const { type, payload = {} } = action;
    switch (type) {
        default:
            return setRetailers(state, payload);
    }
};
