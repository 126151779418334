import { xFetch } from "../../helpers/xhrHelpers";
import { REQUEST_TYPE } from "../../constant";
import { getFundTransferRequestTransactionsUrl } from "../../helpers/url/fund_transfer_transactions";
import { getFundTransferRequestHistoryUrl } from "../../helpers/url/transactions";
const FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_COMPLETED =
    "FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_COMPLETED";
const FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_ERROR =
    "FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_ERROR";
const FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_BY_ORDER_ID_COMPLETED =
    "FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_BY_ORDER_ID_COMPLETED";
const FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_BY_ORDER_ID_ERROR =
    "FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_BY_ORDER_ID_ERROR";

export const fetchFundTransferRequestTransactionsByOrderId = transaction_id => {
    return async dispatch => {
        let response = {};
        try {
            const url = getFundTransferRequestTransactionsUrl(transaction_id);
            response = await xFetch({
                url: url,
                method: REQUEST_TYPE.GET
            });
            const { status } = response;
            if (status) {
                const { payload: { data = {} } = {} } = response;
                dispatch({
                    type: FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_BY_ORDER_ID_COMPLETED,
                    payload: data
                });
            }
        } catch (e) {
            dispatch({
                type: FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_BY_ORDER_ID_ERROR
            });
        }

        return response;
    };
};

export const fetchFundTransferRequestTransactions = data => {
    return async dispatch => {
        let response = {};
        try {
            const url = getFundTransferRequestHistoryUrl();
            response = await xFetch({
                headers: {
                    "Content-Type": "application/json"
                },
                url: url,
                method: REQUEST_TYPE.GET,
                body: data
            });
            const { status } = response;
            if (status) {
                const { payload: { data = {} } = {} } = response;
                dispatch({
                    type: FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_COMPLETED,
                    payload: data
                });
            }
        } catch (e) {
            dispatch({
                type: FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_ERROR
            });
        }

        return response;
    };
};

function setFundTransferRequestTransactionByOrderId(state, data) {
    const { fund_transfer_request_transaction_order_id } = data;
    if (fund_transfer_request_transaction_order_id) {
        return { ...state, ...fund_transfer_request_transaction_order_id };
    }
    return state;
}

function setFundTransferRequestTransactions(state, data) {
    const { fund_transfers, fund_transfer_ids, pages } = data;
    const fund_transfer_request_transactions = {
        fund_transfer_ids,
        fund_transfers,
        pages
    };
    if (fund_transfer_request_transactions) {
        return { ...state, ...fund_transfer_request_transactions };
    }
    return state;
}

export default (state = {}, action = {}) => {
    const { type, payload = {} } = action;
    switch (type) {
        case FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_COMPLETED:
            return setFundTransferRequestTransactions(state, payload);
        case FETCH_FUND_TRANSFER_REQUEST_TRANSACTIONS_BY_ORDER_ID_COMPLETED:
            return setFundTransferRequestTransactionByOrderId(state, payload);
        default:
            return state;
    }
};
