export const getTransactionsUrl = () => {
    return "/transactions";
};

export const getOrdersUrl = () => {
    return "/orders";
};

export const getRetailerOrdersUrl = () => {
    return "/orders/retailers";
};

export const getAllRetailerOrdersByIdUrl = ({ order_id }) => {
    return `/orders/retailers/${order_id}`;
};

export const getFundTransferHistoryUrl = () => {
    return "/fund-transfer-history";
};

export const getFundTransferRequestHistoryUrl = () => {
    return "/fund-transfer-request";
};

export const searchFilterRetailer = () => {
    return "/filter/retailer";
};

export const searchFilterCustomer = () => {
    return "/filter/customer";
};

export const getRetailerByPhoneNumber = () => {
    return "/filter/retailer";
};

export const getCustomerByPhoneNumber = () => {
    return "/filter/customer";
};
