const path = window.location.href;

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const DELETE_FAIL = "DELETE_FAIL";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const HTTP_CODE_SESSION_EXPIRED = 401;
export const FUND_TRANSFER_TYPE_ADVANCE_PAYMENT = "ADVANCE_PAYMENT";
export const FUND_TRANSFER_TYPE_ADJUST_PAYMENT = "ADJUST_PAYMENT";

export const MACHINE_TYPE = {
    EZETAP: { key: "EZETAP", label: "Ezetap", value: "ezetap" },
    PAYTM: { key: "PAYTM", label: "Paytm", value: "paytm" }
};
export const HOST = path
    .split("/")
    .slice(0, 3)
    .join("/");

export const PATH = {
    ROOT: "/",
    LOGIN: "/login",
    DASHBOARD: "/dashboard",
    RETAILER_ORDERS: "/retailer-orders",
    BENEFICIARIES: "/beneficiaries",
    FUND_TRANSFER_REQUESTS: "/fund-transfers-requests",
    FUND_TRANSFER_HISTORY: "/fund-transfers-history",
    CHEQUES_REQUESTS: "/cheques-requests",
    CHEQUES_HISTORY: "/cheques-history",
    LOGOUT: "/logout",
    PROFILE: "/profile",
    ADMIN: "/admin",
    FUND_TRANSFER: "/fund-transfers-history",
    RECONCILIATION: "/recon",
    DASHBOARD_SETTINGS: "/settings",
    CHANGE_PASSWORD: "/change-password",
    MACHINE: "/machine"
};

//order type

export const ORDER_TYPE = {
    FUND_TRANSFER: "FUND_TRANSFER",
    AKSHAMAALA_ORDER: "AKSHAMAALA_ORDER",
    RETAILER_ORDER: "RETAILER_ORDER"
};
//request type
export const REQUEST_TYPE = {
    POST: "post",
    GET: "get",
    PUT: "put",
    DELETE: "delete"
};

export const PAYMENT_DRIVER = {
    PAYTM: "paytm",
    EZETAP: "ezetap",
    CASH: "cash",
    STORE_CREDIT: "store_credit",
    UNATTI_CREDIT: "unnati_credit",
    INPUT_WALLET: "input_wallet",
    FUND_TRANSFER: "fund_transfer",
    CHEQUE: "cheque"
};

export const PAYMENT_METHODS = {
    CARD: { key: "CARD", label: "Card", color: "black" },
    CASH: { key: "CASH", label: "Cash", color: "red" },
    STORE_CREDIT: { key: "STORE_CREDIT", label: "Store Credit", color: "blue" },
    UNATTI_CREDIT: {
        key: "UNATTI_CREDIT",
        label: "Unnati Credit",
        color: "red"
    },
    INPUT_WALLET: {
        key: "INPUT_WALLET",
        label: "Input Wallet",
        color: "red"
    }
};

export const ORDER_STATUS = {
    INIT: { key: "INIT", label: "Initialed", color: "black" },
    PENDING: { key: "PENDING", label: "Pending", color: "black" },
    SUCCESS: { key: "SUCCESS", label: "Success", color: "green" },
    FAILED: { key: "FAILED", label: "Failed", color: "red" },
    REFUND: { key: "REFUND", label: "Refund", color: "blue" },
    CANCELED: { key: "CANCELED", label: "Cancel", color: "red" },
    REVERT_CANCEL: {
        key: "REVERT_CANCEL",
        label: "Revert Cancel",
        color: "red"
    },
    SETTLED_TO_RETAILER: {
        key: "SETTLED_TO_RETAILER",
        label: "Settled to Retailer",
        color: "green"
    },
    FAILED_AND_REFUND_SUCCESS: {
        key: "FAILED_AND_REFUND_SUCCESS",
        label: "Failed and Refund Success",
        color: "red"
    }
};

export const RECONCILIATION_STATUS = {
    // PENDING: { key: "PENDING", label: "Pending", color: "black" },
    // SUCCESS: { key: "SUCCESS", label: "Success", color: "green" },
    FAILED: { key: "FAILED", label: "Failed", color: "red" },
    ON_HOLD: { key: "ON_HOLD", label: "On Hold", color: "blue" },
    REJECTED: { key: "REJECTED", label: "Rejected", color: "orange" },
    QUEUED: { key: "QUEUED", label: "Queued", color: "grey" },
    NOT_REGISTERED: {
        key: "NOT_REGISTERED",
        label: "Not Registered",
        color: "black"
    }
};

export const RECONCILIATION_STATUS_MESSAGE = {
    // PENDING: { key: "PENDING", label: "Pending", color: "black" },
    // SUCCESS: { key: "SUCCESS", label: "Success", color: "green" },
    not_started: { message: "Want to remove this Reconciliation from HOLD ?" },
    on_hold: { message: "Want to put this Reconciliation ON HOLD ?" },
    rejected: { message: "Want to mark this Reconciliation as REJECTED ?" },
    queued: { message: "" }
};

export const ORDER_STATUS_FUND_TRANSFER = {
    approved: { key: "approved", label: "Approved", color: "black" },
    pending: { key: "pending", label: "Pending", color: "black" },
    success: { key: "success", label: "Success", color: "green" },
    failed: { key: "failed", label: "Failed", color: "red" },
    requested: { key: "requested", label: "Requested", color: "grey" },
    rejected: { key: "rejected", label: "Rejected", color: "orange" },
    not_checked: { key: "not_checked", label: "not_checked", color: "blue" }
};

export const ORDER_STATUS_CHEQUES = {
    requested: { key: "requested", label: "Requested", color: "grey" },
    received: { key: "received", label: "Received", color: "black" },
    deposited: { key: "deposited", label: "Deposited", color: "blue" },
    cleared: { key: "cleared", label: "Cleared", color: "green" },
    failed: { key: "failed", label: "Failed", color: "red" },
    rejected: { key: "rejected", label: "Rejected", color: "orange" }
};

export const ALL_ORDER_TYPE = {
    FUND_TRANSFER: {
        key: "FUND_TRANSFER",
        label: "Fund Transfer",
        color: "grey"
    },
    RETAILER_ORDER: {
        key: "RETAILER_ORDER",
        label: "Retailer Order",
        color: "black"
    },
    WALLET_TOPUP: { key: "WALLET_TOPUP", label: "Wallet Topup", color: "blue" },
    AKSHAMAALA_ORDER: {
        key: "AKSHAMAALA_ORDER",
        label: "Farmer Order",
        color: "green"
    },
    ADVANCE_PAYMENT: {
        key: "ADVANCE_PAYMENT",
        label: "Advance Payment",
        color: "green"
    },
    ADJUST_PAYMENT: {
        key: "ADJUST_PAYMENT",
        label: "Adjust Payment",
        color: "green"
    },
    FINAL_PAYMENT: {
        key: "FINAL_PAYMENT",
        label: "Final Payment",
        color: "green"
    }
};

export const ORDER_STATUS_CHEQUES_REQUEST = {
    requested: { key: "requested", label: "Requested", color: "grey" },
    received: { key: "received", label: "Received", color: "black" },
    deposited: { key: "deposited", label: "Deposited", color: "blue" }
};

export const ALL_ORDER_STATUS = {
    approved: { key: "approved", label: "Approved", color: "black" },
    pending: { key: "pending", label: "Pending", color: "black" },
    success: { key: "success", label: "Success", color: "green" },
    failed: { key: "failed", label: "Failed", color: "red" },
    requested: { key: "requested", label: "Requested", color: "grey" },
    rejected: { key: "rejected", label: "Rejected", color: "orange" },
    received: { key: "received", label: "Received", color: "black" },
    deposited: { key: "deposited", label: "Deposited", color: "blue" },
    cancel: { key: "cancel", label: "Cancel", color: "red" },
    revert_cancel: {
        key: "revert_cancel",
        label: "Revert Cancel",
        color: "red"
    },
    settled_to_retailer: {
        key: "settled_to_retailer",
        label: "Settled to Retailer",
        color: "green"
    },
    failed_and_refund_success: {
        key: "failed_and_refund_success",
        label: "Failed and Refund Success",
        color: "red"
    }
};

export const STATUS_REQUESTED = "requested";
export const STATUS_RECEIVED = "received";
export const STATUS_REJECTED = "rejected";
export const STATUS_CLEARED = "cleared";
export const STATUS_DEPOSITED = "deposited";

export const DRAWER = {
    ORDER_DETAILS: "ORDER_DETAILS",
    ADD_BENEFICIARIES: "ADD_BENEFICIARIES",
    BENEFICIARY_DETAILS: "BENEFICIARY_DETAILS",
    FUND_TRANSFER_DETAILS: "FUND_TRANSFER_DETAILS",
    FUND_TRANSFER_REQUEST_DETAILS: "FUND_TRANSFER_REQUEST_DETAILS",
    CHEQUE_DETAILS: "CHEQUE_DETAILS",
    CHEQUE_REQUEST_DETAILS: "CHEQUE_REQUEST_DETAILS",
    ADD_MACHINES: "ADD_MACHINES",
    MACHINE_DETAILS: "MACHINE_DETAILS"
};

export const ACCOUNT_STATUS = {
    PENDING: "pending",
    VERIFIED: "success",
    FAILED: "failed",
    NOT_CHECKED: "not_checked"
};
