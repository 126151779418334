function setData(state, data) {
    const { transaction_history } = data;
    if (transaction_history) {
        return { ...state, ...transaction_history };
    }
    return state;
}

export default (state = {}, action = {}) => {
    const { type, payload = {} } = action;
    switch (type) {
        default:
            return setData(state, payload);
    }
};
