import { xFetch } from "../../helpers/xhrHelpers";
import { REQUEST_TYPE } from "../../constant";
import { getFundTransferTransactionsUrl } from "../../helpers/url/fund_transfer_transactions";

const FETCH_FUND_TRANSFER_TRANSACTIONS_COMPLETED =
    "FETCH_FUND_TRANSFER_TRANSACTIONS_COMPLETED";
const FETCH_FUND_TRANSFER_TRANSACTIONS_ERROR =
    "FETCH_FUND_TRANSFER_TRANSACTIONS_ERROR";

export const fetchFundTransferTransactions = transaction_id => {
    return async dispatch => {
        let response = {};
        try {
            const url = getFundTransferTransactionsUrl(transaction_id);
            response = await xFetch({
                url: url,
                method: REQUEST_TYPE.GET
            });
            const { status } = response;
            if (status) {
                const { payload: { data = {} } = {} } = response;
                dispatch({
                    type: FETCH_FUND_TRANSFER_TRANSACTIONS_COMPLETED,
                    payload: data
                });
            }
        } catch (e) {
            dispatch({
                type: FETCH_FUND_TRANSFER_TRANSACTIONS_ERROR
            });
        }

        return response;
    };
};

function setFundTransferTransactions(state, data) {
    const { fund_transfer_transactions } = data;
    if (fund_transfer_transactions) {
        return { ...state, ...fund_transfer_transactions };
    }
    return state;
}

export default (state = {}, action = {}) => {
    const { type, payload = {} } = action;
    switch (type) {
        default:
            return setFundTransferTransactions(state, payload);
    }
};
